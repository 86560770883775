import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { useRouter } from 'src/routes/hook';
import { PATH_AFTER_LOGIN, PATH_LOGIN_OTP, PATH_AFTER_LOGOUT } from 'src/config-global';

// utils
import axios, { endpoints } from 'src/utils/axios';
//
import { ACTION_SWIPE } from 'yet-another-react-lightbox';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  require_opt: false,
  local_email: '',
  local_password: '',
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
      local_email: "",
      local_password: "",
      require_otp: false,
    };
  }
  if (action.type === 'REQUIRE_OTP') {
    return {
      ...state,
      local_email: action.payload.local_email,
      local_password: action.payload.local_password,
      require_opt: action.payload.require_opt,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const router = useRouter();

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.post(endpoints.auth.me);

        const { user } = response.data;

        dispatch({
          type: 'INITIAL',
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (email, password) => {
      const data = {
        email,
        password,
      };

      const response = await axios.post(endpoints.auth.login, data);

      const { accessToken, user, require_otp } = response.data;

      if (require_otp) {
        dispatch({
          type: 'REQUIRE_OTP',
          payload: {
            local_email: email,
            local_password: password,
            require_otp: true,
          },
        });
        router.push(PATH_LOGIN_OTP);
      } 
      
      if (user && accessToken) {
        setSession(accessToken);

        dispatch({
          type: 'LOGIN',
          payload: {
            user,
          },
        });
        router.push(PATH_AFTER_LOGIN);
      }
    },
    [router]
  );

  const login_otp = useCallback(async (email, password, otp_passcode) => {
    const data = {
      email,
      password,
      otp_passcode,
    };

    const response = await axios.post(endpoints.auth.login_otp, data);

    const { accessToken, user } = response.data;

    if (user && accessToken) {
      setSession(accessToken);
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });
      router.push(PATH_AFTER_LOGIN);
    }

  }, [router]);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
    };

    const response = await axios.post(endpoints.auth.register, data);

    const { accessToken, user } = response.data;

    localStorage.setItem(STORAGE_KEY, accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
    router.push(PATH_AFTER_LOGOUT);

  }, [router]);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      require_opt: state.require_opt,
      local_email: state.local_email,
      local_password: state.local_password,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      login_otp,
      register,
      logout,
    }),
    [login, login_otp, logout, register, state.user, status, state.require_opt, state.local_email, state.local_password]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
