export const kind_street = [
  'RUA',
  'AVENIDA',
  'ALAMEDA',
  'CONDOMÍNIO',
  'ESTRADA',
  'PRAÇA',
  'RODOVIA',
  'TRAVESSA',
  'QUADRA',
  'ESTRADA VICINAL',
  'ESTRADA MUNICIPAL',
  'ÁREA RURAL',
  'VIELA',
  'OUTROS',
];
