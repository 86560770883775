import { useEffect, useState, useCallback, useMemo } from 'react';
import { Howl } from 'howler';
import { useAuthContext } from 'src/auth/hooks';
import { socket } from 'src/socket/channelsSocket';
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
import { useDispatch } from 'src/redux/store';
import { setUnreadCount } from 'src/redux/slices/channel';
import { removeHtmlTagsAndReplaceWithWhitespace } from 'src/sections/channels/utils';

function NotificationComponent() {

  const { user } = useAuthContext();

  const router = useRouter();

  const dispatch = useDispatch();

  const song = useMemo(
    () =>
      new Howl({
        src: ['/channel_notification.wav'],
      }),
    []
  );

  const playSong = useCallback(() => {
    song.play();
  }, [song]);

  const showNotification = useCallback(
    (title, body, icon, channel_id, channels_labels_id) => {
      playSong();
      if (Notification.permission === 'granted') {
        const notification = new Notification(title, {
          body: removeHtmlTagsAndReplaceWithWhitespace(body),
          icon,
        });

        notification.onclick = () => {
          router.push(
            `${paths.dashboard.channels}?id=${channel_id}&channels_labels_id=${channels_labels_id}`
          );
          notification.close();
          window.focus();
        };
      }
    },
    [playSong, router]
  );

  useEffect(() => {
    socket.connect();
    socket.on(`conversationNotification-${user?.tenant_domain}`, (newMessage) => {
      const {
        body,
        channel_name,
        chat_profile_image_url,
        mimetype,
        channels_labels_id,
        channel_id,
        channel_user_ids,
        has_media,
        as_reaction
      } = newMessage;
      const hasImage = String(mimetype).includes('image');
      const isAudio = String(mimetype).includes('audio');
      const isPdf = String(mimetype).includes('pdf');
      let messageBody = body;
      if (hasImage) {
        messageBody = 'Imagem recebida!';
      }
      if (isPdf) {
        messageBody = 'PDF recebido!';
      }
      if (isAudio) {
        messageBody = 'Áudio recebido';
      }
      if (has_media && !isAudio && !isPdf && !hasImage) {
        messageBody = 'Arquivo recebido!';
      }
      if (as_reaction) {
        messageBody = `Reação recebida ${newMessage?.reactions[0].body}`;
      }
      if (channel_user_ids?.includes(user.id) || !channel_user_ids) {
        showNotification(
          channel_name,
          messageBody,
          chat_profile_image_url,
          channel_id,
          channels_labels_id
        );
      }
    });
    return () => {
      socket.off(`conversationNotification-${user?.tenant_domain}`);
    };
  }, [user, showNotification]);

  useEffect(() => {
    // Check browser support
    if ('Notification' in window && 'serviceWorker' in navigator) {
      // Request permission
      if (Notification.permission !== 'granted') {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            console.log('Notification permission granted.');
          } else {
            console.log('Notification permission denied.');
          }
        });
      }
    } else {
      console.log('Web Notifications are not supported in this browser.');
    }
  }, []);

  useEffect(() => {
    socket.emit('getUnreadChannelsLabelsCount', {
      tenant_id: user?.tenant_domain,
      user_id: user.id,
    });
    socket.on(`channelUpdate-${user?.tenant_domain}`, (channel_id) => {
      socket.emit('getUnreadChannelsLabelsCount', {
        tenant_id: user?.tenant_domain,
        user_id: user.id,
      });
    });
    socket.on(`channelsLabelsUneadCount-${user.id}-${user?.tenant_domain}`, (data) => {
      dispatch(setUnreadCount(data));
    });

    return () => {
      socket.off(`channelsLabelsUneadCount-${user.id}-${user?.tenant_domain}`);
      socket.off(`channelUpdate-${user?.tenant_domain}`);
    };
  }, [user, dispatch]);

  return null;
}

export default NotificationComponent;

NotificationComponent.propTypes = {};
