import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
import CleanLayout from 'src/layouts/clean';
import SimpleLayout from 'src/layouts/simple';
import CompactLayout from 'src/layouts/compact';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

export const Homes = lazy(() => import('src/pages/home'));
export const DummyForms = lazy(() => import('src/pages/dummy-forms'));
const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const FaqsPage = lazy(() => import('src/pages/faqs'));
const AboutPage = lazy(() => import('src/pages/about-us'));
const ContactPage = lazy(() => import('src/pages/contact-us'));
const PricingPage = lazy(() => import('src/pages/pricing'));
const PaymentPage = lazy(() => import('src/pages/payment'));
const ComingSoonPage = lazy(() => import('src/pages/coming-soon'));
const MaintenancePage = lazy(() => import('src/pages/maintenance'));
// PRODUCT
const ProductListPage = lazy(() => import('src/pages/product/list'));
const ProductDetailsPage = lazy(() => import('src/pages/product/details'));
const ProductCheckoutPage = lazy(() => import('src/pages/product/checkout'));
// BLOG
const PostListPage = lazy(() => import('src/pages/post/list'));
const PostDetailsPage = lazy(() => import('src/pages/post/details'));

// ----------------------------------------------------------------------

const NewClientPage = lazy(() => import('src/pages/forms/new-client'));
const NewClientRentPage = lazy(() => import('src/pages/forms/new-client-rent'));
const NewClientSellPage = lazy(() => import('src/pages/forms/new-client-sell'));

const NewClientBondsmanPage = lazy(() => import('src/pages/forms/new-client-bondsman'));
const NewClientDocsPage = lazy(() => import('src/pages/forms/new-client-docs'));
const NewClientResidentialRentPage = lazy(() => import('src/pages/forms/new-client-residential'));
const NewClientCommercialRentPage = lazy(() => import('src/pages/forms/new-client-commercial'));


export const formsRootPage = (
  <Suspense fallback={<SplashScreen />}>
    <DummyForms />
  </Suspense>
);

export const formRoutes = [
  {
    element: (
      <CleanLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CleanLayout>
    ),
    children: [
      { path: 'novo-cliente', element: <NewClientPage /> },
      { path: 'novo-cliente-alugar', element: <NewClientRentPage /> },
      { path: 'novo-cliente-fiador', element: <NewClientBondsmanPage /> },
      { path: 'cliente-docs', element: <NewClientDocsPage /> },
      { path: 'alugar-residencial', element: <NewClientResidentialRentPage /> },
      { path: 'alugar-comercial', element: <NewClientCommercialRentPage /> },
      { path: 'novo-cliente-venda', element: <NewClientSellPage /> },
      // {
      //   path: 'post',
      //   children: [
      //     { element: <PostListPage />, index: true },
      //     { path: 'list', element: <PostListPage /> },
      //     { path: ':title', element: <PostDetailsPage /> },
      //   ],
      // },
    ],
  },
  // {
  //   element: (
  //     <SimpleLayout>
  //       <Suspense fallback={<SplashScreen />}>
  //         <Outlet />
  //       </Suspense>
  //     </SimpleLayout>
  //   ),
  //   children: [
  //     { path: 'pricing', element: <PricingPage /> },
  //     { path: 'payment', element: <PaymentPage /> },
  //   ],
  // },
  // {
  //   element: (
  //     <CompactLayout>
  //       <Suspense fallback={<SplashScreen />}>
  //         <Outlet />
  //       </Suspense>
  //     </CompactLayout>
  //   ),
  //   children: [
  //     { path: 'coming-soon', element: <ComingSoonPage /> },
  //     { path: 'maintenance', element: <MaintenancePage /> },
  //     { path: '500', element: <Page500 /> },
  //     { path: '404', element: <Page404 /> },
  //     { path: '403', element: <Page403 /> },
  //   ],
  // },
];
