import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';
import { api_path as WHATSAPP_API_ENDPOINT } from 'src/socket/whatsappSocket';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    me: '/authenticate_token',
    login: '/authenticate',
    login_otp: '/authenticate_otp',
    register: '/api/auth/register',
  },
  client: {
    list: '/clients',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  whatsapp: {
    root: `${WHATSAPP_API_ENDPOINT}`,
    list: `${WHATSAPP_API_ENDPOINT}/chats`,
    tags: `${WHATSAPP_API_ENDPOINT}/tags`,
    search_tags: `${WHATSAPP_API_ENDPOINT}/tags/search`,
  },
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
