import { format, getTime, formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns';
import { ptBR, enUS, es, fr, de } from 'date-fns/locale'

const userLocale =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;

function defineLocale(locale) {
  if (locale.includes("pt")) {
    return ptBR
  }
  if (locale.includes("en")) {
    return enUS
  }
  if (locale.includes("es")) {
    return es
  }
  if (locale.includes("fr")) {
    return fr
  }
  if (locale.includes("de")) {
    return de
  }
  return ptBR
}


// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm, {locale: defineLocale(userLocale)}) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm, {locale: defineLocale(userLocale)}) : '';
}

export function fTimeDate(date, newFormat) {
  const fm = newFormat || 'p dd/MM/yyyy ';

  return date ? format(new Date(date), fm, {locale: defineLocale(userLocale)}) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date, addSuffix = true, includeSeconds = false) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix,
        locale: defineLocale(userLocale),
      })
    : '';
}


export function fToNowStrict(date, addSuffix = true, includeSeconds = false) {
  return date
    ? formatDistanceToNowStrict(new Date(date), {
        addSuffix,
        locale: defineLocale(userLocale),
      })
    : '';
}
