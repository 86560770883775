import { useEffect, useState, useCallback, useMemo } from 'react';
import { Howl } from 'howler';
import { useAuthContext } from 'src/auth/hooks';
import { socket } from 'src/socket/whatsappSocket';
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
import { useDispatch } from 'src/redux/store';
import { setUnreadCount } from 'src/redux/slices/whatsapp';

function NotificationComponent() {
  const { user } = useAuthContext();

  const router = useRouter();

  const dispatch = useDispatch();

  const song = useMemo(
    () =>
      new Howl({
        src: ['/simple_notification.mp3'],
      }),
    []
  );

  const playSong = useCallback(() => {
    song.play();
  }, [song]);

  const showNotification = useCallback(
    (title, body, icon, chat_id, client_id) => {
      playSong();
      if (Notification.permission === 'granted') {
        const notification = new Notification(title, {
          body,
          icon,
        });

        notification.onclick = () => {
          router.push(`${paths.dashboard.whatsapp}?chat_id=${chat_id}&sender_id=${client_id}`);
          notification.close();
          window.focus();
        };
      }
    },
    [playSong, router]
  );

  useEffect(() => {
    socket.connect();
    user?.senders.forEach((item) => {
      socket.on(`messageNotification-${item.value}`, (newMessage) => {
        if (newMessage?.chat_user_id === user.id) {
          const {
            body,
            chat_local_name,
            chat_name,
            chat_short_name,
            chat_profile_image_url,
            mimetype,
            client_id,
            chat_id,
            has_media,
          } = newMessage;
          const hasImage = String(mimetype).includes('image');
          const isAudio = String(mimetype).includes('audio');
          const isPdf = String(mimetype).includes('pdf');
          let messageBody = body;
          if (hasImage) {
            messageBody = 'Imagem recebida!';
          }
          if (isPdf) {
            messageBody = 'PDF recebido!';
          }
          if (isAudio) {
            messageBody = 'Áudio recebido';
          }
          if (has_media && !isAudio && !isPdf && !hasImage) {
            messageBody = 'Arquivo recebido!';
          }
          showNotification(
            chat_local_name || chat_name || chat_short_name,
            messageBody,
            chat_profile_image_url,
            chat_id,
            client_id
          );
        }
      });
    });
    return () => {
      user?.senders.forEach((item) => {
        socket.off(`messageNotification-${item.value}`);
      });
    };
  }, [user, showNotification]);

  useEffect(() => {
    // Check browser support
    if ('Notification' in window && 'serviceWorker' in navigator) {
      // Request permission
      if (Notification.permission !== 'granted') {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            console.log('Notification permission granted.');
          } else {
            console.log('Notification permission denied.');
          }
        });
      }
    } else {
      console.log('Web Notifications are not supported in this browser.');
    }
  }, []);

  useEffect(() => {
    socket.emit('getUnreadChatsCount', {
      client_ids: user?.senders.map((el) => el.value),
      user_id: user?.id,
    });
    socket.on(`chatsUnreadCount-${user.id}`, (unread_count) => {
      dispatch(setUnreadCount(unread_count));
    });
    user?.senders.forEach((item) => {
      socket.on(`chatsUpdate-${user.id}-${item.value}`, (data) => {
        socket.emit('getUnreadChatsCount', {
          client_ids: user?.senders.map((el) => el.value),
          user_id: user?.id,
        });
      });
      socket.on(`chatUpdate-${item.value}`, (data) => {
        socket.emit('getUnreadChatsCount', {
          client_ids: user?.senders.map((el) => el.value),
          user_id: user?.id,
        });
      });
    });
    return () => {
      socket.off(`chatsUnreadCount-${user.id}`);
      user?.senders.forEach((item) => {
        socket.off(`chatsUpdate-${user.id}-${item.value}`);
        socket.off(`chatUpdate-${item.value}`);
      });
    };
  }, [user, dispatch]);

  return null;
}

export default NotificationComponent;

NotificationComponent.propTypes = {};
