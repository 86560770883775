import io from 'socket.io-client';

export const api_path =
  // process.env.NODE_ENV === 'production' ? 'http://localhost:8091' : 'http://localhost:8091';
  process.env.NODE_ENV === 'production' ? 'https://tools.zieltech.me' : 'https://tools.zieltech.me';

export const socket = io(api_path, {
  autoConnect: false,
});




export const labelsLoading = false;
export const labels = [
  {
    id: "active_users",
    type: "system",
    name: "Usuários Ativos",
    unreadCount: 3,
  },
  // {
  //   id: "forums",
  //   type: "custom",
  //   name: "forums",
  //   unreadCount: 3,
  //   color: "#ac252d",
  // },
  {
    id: "inactive_users",
    type: "system",
    name: "Usuários Inativos",
    unreadCount: 0,
  },
]