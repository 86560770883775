const caseAllowedGroups = {
  admin_dashboard: { usages: undefined, groups: ['admin'] },
  whatsapp_tags: { usages: undefined, groups: ['admin'] },
  whatsapp_archive_no_notification: { usages: undefined, groups: ['admin'] },
  whatsapp_see_all_users_chats: { usages: undefined, groups: ['admin'] },
};

const userAllowance = {
  company_statistics: [1, 2, 3],
};

export function checkUserGroupCase(caseType, user) {
  if (!caseAllowedGroups[caseType]) {
    return user.admin;
  }

  // if (caseAllowedGroups[caseType].usage) {

  // }

  return user.admin || caseAllowedGroups[caseType].groups.includes(user.group);
}

export function checkUserAllowance(caseType, user) {
  if (!userAllowance[caseType]) {
    return false;
  }

  // if (caseAllowedGroups[caseType].usage) {

  // }

  return userAllowance[caseType].includes(user.id);
}

export function checkTenantModules(caseType, user) {
  if (!user.tenant_modules) {
    return false;
  }

  if (user.tenant_modules.includes('all')) {
    return true;
  }

  return user.tenant_modules.includes(caseType);
}
