import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  unread_count: 0,
  quoted_message_text: null,
  quoted_message_id: null,
  quoted_message_chat_id: null,
  quoted_message_sender_id: null,
};

const slice = createSlice({
  name: 'whatsapp',
  initialState,
  reducers: {
    setQuotedMessage(state, action) {
      const message = action.payload;

      state.quoted_message_text = message.quoted_message_text;
      state.quoted_message_id = message.quoted_message_id;
      state.quoted_message_chat_id = message.quoted_message_chat_id;
      state.quoted_message_sender_id = message.quoted_message_sender_id;
    },

    resetQuotedMessage(state) {
      state.quoted_message_text = null;
      state.quoted_message_id = null;
      state.quoted_message_chat_id = null;
      state.quoted_message_sender_id = null;
    },

    setUnreadCount(state, action) {
      const unread_count = action.payload;

      state.unread_count = unread_count;
    },

    resetUnreadCount(state) {
      state.unread_count = 0;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setQuotedMessage, resetQuotedMessage, setUnreadCount, resetUnreadCount } =
  slice.actions;
