export const brazilian_states = [
  {name: "Acre", short_name: "AC"},
  {name: "Alagoas", short_name: "AL"},
  {name: "Amapá", short_name: "AP"},
  {name: "Amazonas", short_name: "AM"},
  {name: "Bahia", short_name: "BA"},
  {name: "Ceará", short_name: "CE"},
  {name: "Distrito Federal", short_name: "DF"},
  {name: "Espírito Santo", short_name: "ES"},
  {name: "Goiás", short_name: "GO"},
  {name: "Maranhão", short_name: "MA"},
  {name: "Mato Grosso", short_name: "MT"},
  {name: "Mato Grosso do Sul", short_name: "MS"},
  {name: "Minas Gerais", short_name: "MG"},
  {name: "Pará", short_name: "PA"},
  {name: "Paraíba", short_name: "PB"},
  {name: "Paraná", short_name: "PR"},
  {name: "Pernambuco", short_name: "PE"},
  {name: "Piauí", short_name: "PI"},
  {name: "Rio de Janeiro", short_name: "RJ"},
  {name: "Rio Grande do Norte", short_name: "RN"},
  {name: "Rio Grande do Sul", short_name: "RS"},
  {name: "Rondônia", short_name: "RO"},
  {name: "Roraima", short_name: "RR"},
  {name: "Santa Catarina", short_name: "SC"},
  {name: "São Paulo", short_name: "SP"},
  {name: "Sergipe", short_name: "SE"},
  {name: "Tocantins", short_name: "TO"}
];