export const working_conventions = [
  'Registrado em Empresa Privada',
  'Autônomo Informal',
  'MEI',
  'Empresário',
  'Aposentado',
  'Pensionista',
  'Servidor Público',
  'Militar',
  'Não Exerce Atividade Remunerada',
];
