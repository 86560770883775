import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const DEFAULT = {
  table: '#C8FACD',
  lighter: '#F4F6F8',
  light: '#DFE3E8',
  main: '#919EAB',
  dark: '#454F5B',
  darker: '#212B36',
  contrastText: '#FFFFFF',
};

const PRIMARY = {
  lighter: '#C8FAD6',
  light: '#5BE49B',
  main: '#00A76F',
  dark: '#007867',
  darker: '#004B50',
  contrastText: '#FFFFFF',
};

const SECONDARY = {
  lighter: '#EFD6FF',
  light: '#C684FF',
  main: '#8E33FF',
  dark: '#5119B7',
  darker: '#27097A',
  contrastText: '#FFFFFF',
};

const INFO = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  lighter: '#D3FCD2',
  light: '#77ED8B',
  main: '#22C55E',
  dark: '#118D57',
  darker: '#065E49',
  contrastText: '#ffffff',
};

const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

const DANGER = {
  table: '#FFE9D5',
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

const POWER = {
  table: '#d7a8df',
  lighter: '#d7a8df',
  light: '#bb6bc9',
  main: '#ab47bc',
  dark: '#773183',
  darker: '#631976',
  contrastText: '#FFFFFF',
};

const ORANGE = {
  table: '#ffa183',
  lighter: '#ffa183',
  light: '#ff784e',
  main: '#ff5722',
  dark: '#b23c17',
  darker: '#a13311',
  contrastText: '#FFFFFF',
};

const TAGS_WHITE = {
  tag01: '#BAF3DB',
  tag02: '#F8E6A0',
  tag03: '#FFE2BD',
  tag04: '#FFD2CC',
  tag05: '#DFD8FD',
  tag06: '#4BCE97',
  tag07: '#E2B203',
  tag08: '#FAA53D',
  tag09: '#F87462',
  tag10: '#9F8FEF',
  tag11: '#1F845A',
  tag12: '#946F00',
  tag13: '#B65C02',
  tag14: '#CA3521',
  tag15: '#6E5DC6',
  tag16: '#CCE0FF',
  tag17: '#C1F0F5',
  tag18: '#D3F1A7',
  tag19: '#FDD0EC',
  tag20: '#DCDFE4',
  tag21: '#579DFF',
  tag22: '#60C6D2',
  tag23: '#94C748',
  tag24: '#E774BB',
  tag25: '#8590A2',
  tag26: '#0C66E4',
  tag27: '#1D7F8C',
  tag28: '#5B7F24',
  tag29: '#AE4787',
  tag30: '#626F86',
};



const TAGS_DARK = {
  tag01: '#164B35',
  tag02: '#533F04',
  tag03: '#5F3811',
  tag04: '#601E16',
  tag05: '#352C63',
  tag06: '#216E4E',
  tag07: '#7F5F01',
  tag08: '#974F0C',
  tag09: '#AE2A19',
  tag10: '#5E4DB2',
  tag11: '#4BCE97',
  tag12: '#E2B203',
  tag13: '#FAA53D',
  tag14: '#F87462',
  tag15: '#9F8FEF',
  tag16: '#09326C',
  tag17: '#1D474C',
  tag18: '#37471F',
  tag19: '#50253F',
  tag20: '#454F59',
  tag21: '#0055CC',
  tag22: '#206B74',
  tag23: '#4C6B1F',
  tag24: '#943D73',
  tag25: '#596773',
  tag26: '#579DFF',
  tag27: '#60C6D2',
  tag28: '#94C748',
  tag29: '#E774BB',
  tag30: '#8C9BAB',
};

const COMMON = {
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  // tailswindcss colors
  teal: INFO,
  blue: INFO,
  cyan: INFO,
  sky: INFO,
  green: SUCCESS,
  emerald: SUCCESS,
  lime: SUCCESS,
  pink: POWER,
  purple: POWER,
  violet: POWER,
  red: DANGER,
  rose: DANGER,
  yellow: WARNING,
  amber: WARNING,
  zinc: ORANGE,
  // tailswindcss colors
  divider: alpha(GREY[500], 0.2),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export function palette(mode) {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: GREY[200],
    },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
    tags: TAGS_WHITE,
  };

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.12),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
    tags: TAGS_DARK,
    primary: {
      ...COMMON.primary,
      table: PRIMARY.dark
    },
    secondary: {
      ...COMMON.secondary,
      table: SECONDARY.dark,
    },
    info: {
      ...COMMON.info,
      table: INFO.dark,
    },
    success: {
      ...COMMON.success,
      table: SUCCESS.dark,
    },
    warning: {
      ...COMMON.warning,
      table: WARNING.dark,
    },
    error: {
      ...COMMON.error,
      table: ERROR.dark,
    },
    danger: {
      ...COMMON.danger,
      table: DANGER.dark,
    },
    power: {
      ...COMMON.power,
      table: POWER.dark,
    },
    orange: {
      ...COMMON.orange,
      table: ORANGE.dark,
    },
    grey: {
      ...COMMON.grey,
      table: GREY.dark,
    },
    default: {
      ...COMMON.grey,
      table: DEFAULT.dark,
    }
  };

  return mode === 'light' ? light : dark;
}
