import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

export default function SearchNotFound({ query, sx, ...other }) {
  const { t } = useLocales();

  return query ? (
    <Paper
      sx={{
        bgcolor: 'unset',
        textAlign: 'center',
        ...sx,
      }}
      {...other}
    >
      <Typography variant="h6" gutterBottom sx={{textTransform: "capitalize"}}>
        {t("not_found")}
      </Typography>

      <Typography variant="body2">
        {t("no_results_for")} &nbsp;
        <strong>&quot;{query}&quot;</strong>.
        <br /> {t("try_checking_for_typos")}
      </Typography>
    </Paper>
  ) : (
    <Typography variant="body2" sx={sx}>
      {t("please_enter_keywords")}
    </Typography>
  );
}

SearchNotFound.propTypes = {
  query: PropTypes.string,
  sx: PropTypes.object,
};
