import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { PATH_HOME } from 'src/config-global';
// @mui
// import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
import Image from 'src/components/image';
import image from '../../assets/logo/favicon.png';
// ----------------------------------------------------------------------

const Logo = image;

export default Logo;
