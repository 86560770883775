import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import checkoutReducer from './slices/checkout';
import whatsappReducer from './slices/whatsapp';
import channelReducer from './slices/channel';

// ----------------------------------------------------------------------

const checkoutPersistConfig = {
  key: 'checkout',
  storage,
  keyPrefix: 'redux-',
};

const whatsappPersistConfig = {
  key: 'whatsapp',
  storage,
  keyPrefix: 'redux-',
};

const channelPersistConfig = {
  key: 'channel',
  storage,
  keyPrefix: 'redux-',
};
export const rootReducer = combineReducers({
  checkout: persistReducer(checkoutPersistConfig, checkoutReducer),
  whatsapp: persistReducer(whatsappPersistConfig, whatsappReducer),
  channel: persistReducer(channelPersistConfig, channelReducer),
});
