import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
// ----------------------------------------------------------------------

export default function CleanLayout({ children }) {
  return (children);
}

CleanLayout.propTypes = {
  children: PropTypes.node,
};
