export const dailyStoicQuote = () => {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const diff = now.getTime() - startOfYear.getTime();
    const dayOfYear = Math.floor(diff / (1000 * 60 * 60 * 24)) + 1;
    const modulo = dayOfYear % 23;
    return utilStoicQuotes[modulo];
  };
  
export const utilStoicQuotes = [
  {
    text: 'Se um homem não sabe para qual porto navega, nenhum vento lhe é favorável.',
    author: 'Sêneca',
  },
  {
    text: 'Estamos mais frequentemente assustados do que feridos; e sofremos mais na imaginação do que na realidade.',
    author: 'Sêneca',
  },
  {
    text: 'A vida é muito curta e ansiosa para aqueles que esquecem o passado, negligenciam o presente e temem o futuro.',
    author: 'Sêneca',
  },
  {
    text: 'Este é o nosso grande erro: pensar que ansiamos pela morte. A maior parte da morte já se foi. O tempo que passou, pertence à morte.',
    author: 'Sêneca',
  },
  {
    text: 'Nenhuma pessoa tem o poder de ter tudo o que deseja, mas está em seu poder não querer o que não tem e fazer bom uso do que tem de bom grado.',
    author: 'Sêneca',
  },
  {
    text: 'Nada, na minha opinião, é uma prova melhor de uma mente bem organizada do que a capacidade de um homem parar exatamente onde está e passar algum tempo em sua própria companhia.',
    author: 'Sêneca',
  },
  {
    text: 'As pessoas são frugais na guarda de seus bens pessoais; mas assim que se trata de desperdiçar tempo, eles perdem aquilo em que é justamente certo ser mesquinho.',
    author: 'Sêneca',
  },
  { text: 'A alma é tingida com a cor de seus pensamentos.', author: 'Marco Aurélio' },
  { text: 'O que fazemos agora ecoa pela eternidade.', author: 'Marco Aurélio' },
  {
    text: 'A felicidade de sua vida depende da qualidade de seus pensamentos.',
    author: 'Marco Aurélio',
  },
  {
    text: 'Não é a morte que um homem deve temer, mas ele deve temer nunca começar a viver.',
    author: 'Marco Aurélio',
  },
  {
    text: 'Não perca mais tempo discutindo sobre o que um bom homem deveria ser. Seja.',
    author: 'Marco Aurélio',
  },
  {
    text: 'Não se entregue aos sonhos de ter o que você não tem, mas calcule a principal das bênçãos que você possui, e então, felizmente, lembre-se de como você ansiaria por elas se não fossem suas.',
    author: 'Marco Aurélio',
  },
  {
    text: 'Pense em você como alguém que morreu. Você viveu sua vida. Agora, agarre os dias que sobraram e viva-os de maneira adequada. Aquele que não transmite luz cria sua própria escuridão.',
    author: 'Marco Aurélio',
  },
  {
    text: 'Muitas vezes me perguntei como é que todo homem se ama mais do que todos os outros homens, mas dá menos valor à sua própria opinião do que à dos outros.',
    author: 'Marco Aurélio',
  },
  {
    text: 'O objetivo da vida não é estar do lado da maioria, mas escapar de se encontrar nas fileiras dos loucos.',
    author: 'Marco Aurélio',
  },
  {
    text: 'Não espere que tudo aconteça como você deseja, mas sim como tudo realmente deve acontecer; então sua vida irá fluir bem.',
    author: 'Epicteto',
  },
  {
    text: 'Primeiro diga a si mesmo o que você seria; e então faça o que você tem que fazer.',
    author: 'Epicteto',
  },
  {
    text: 'Controle o seu desejo – não coloque o seu coração em tantas coisas, só assim você obterá o que precisa.',
    author: 'Epicteto',
  },
  { text: 'Não explique sua filosofia, mas sim incorpore-a.', author: 'Epicteto' },
  {
    text: 'É por isso que os filósofos nos alertam para não ficarmos satisfeitos com o mero aprendizado, mas para adicionar a prática e depois o treinamento. Pois com o passar do tempo esquecemos o que aprendemos e acabamos fazendo o oposto, e temos opiniões opostas do que deveríamos.',
    author: 'Epicteto',
  },
  {
    text: 'A principal tarefa na vida é simplesmente esta: identificar e separar as coisas para que eu possa dizer claramente a mim mesmo quais são as coisas externas que não estão sob meu controle e quais têm a ver com as escolhas que eu realmente controlo. Onde então procuro o bem e o mal? Não às coisas externas incontroláveis, mas dentro de mim, nas escolhas que são minhas.',
    author: 'Epicteto',
  },
  {
    text: 'Se alguém lhe disser que certa pessoa fala mal de você, não dê desculpas sobre o que é dito de você, mas responda: ‘Ele não sabia sobre meus outros defeitos, caso contrário, não teria mencionado apenas esses.',
    author: 'Epicteto',
  },
];
